import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Helmet from "react-helmet"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PostCard from "../components/PostCard"
import HomeJsonLd from "../components/json/HomeJsonLd"

import styled from "styled-components"

const PagenationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
`

const PageNumber = styled.span`
  display: inline-block;
  text-align: center;
  padding: 0.25rem 0.8rem;
  border-radius: 7px;
  margin: 6px;
  color: ${(props) => (props.isCurrentPage ? "#fff" : "#0070f3")};
  background: ${(props) => (props.isCurrentPage ? "#0070f3" : "")};
  box-shadow: ${(props) =>
    props.isCurrentPage ? "rgba(0, 118, 255, 0.39) 0px 2px 6px 0px" : ""};

  &:hover {
    background: ${(props) =>
      props.isCurrentPage
        ? "rgba(0, 118, 255, 0.9)"
        : "rgba(0, 118, 255, 0.1)"};
  }
`

class BlogList extends React.Component {
  render() {
    //GraphQLによって得られるデータは data という名前でpropsに付与される
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const posts = data.allMarkdownRemark.edges
    let i = 0

    const { currentPage, numPages } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <Helmet>
          <link rel="canonical" href="https://englissu.com" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0"
          />
        </Helmet>
        <HomeJsonLd />
        {posts.map(({ node }) => {
          if (node.frontmatter.category !== "self") {
            i = i + 1
            return <PostCard key={node.fields.slug} node={node} i={i} />
          }
        })}

        <PagenationWrapper>
          {Array.from({ length: numPages }, (_, i) => (
            <Link
              key={`pagination-number${i + 1}`}
              to={`/${i === 0 ? "" : i + 1}`}
            >
              <PageNumber isCurrentPage={i === currentPage - 1 ? true : false}>
                {i + 1}
              </PageNumber>
            </Link>
          ))}
        </PagenationWrapper>
      </Layout>
    )
  }
}

export default BlogList

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
            description
            category
            tags
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 630)
              }
            }
          }
        }
      }
    }
  }
`
